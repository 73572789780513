import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from './../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FeaturedService {
  apiBaseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) {
   }

  getFeaturedApartments(): Observable<any> {
    console.log('API:' + environment.apiBaseUrl);
    const apartmentUrl = this.apiBaseUrl + 'api/getFeaturedApartments';  // URL to web api;
    console.log(apartmentUrl);
    return this.http.get<any[]>(apartmentUrl);
  }
  getFeaturedApartment(nid: string): Observable<any> {
    const apartmentUrl = this.apiBaseUrl + 'api/getFeaturedApartment/' + nid;  // URL to web api;
    console.log(apartmentUrl);
    return this.http.get<any[]>(apartmentUrl);
  }
  getAvailabilityApartment(nid: string): Observable<any> {
    const availabilityUrl = this.apiBaseUrl + 'api/getAvailabilityApartment/' + nid;
    console.log(availabilityUrl);
    return this.http.get<any[]>(availabilityUrl);
  }

}
