import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-legacy-apartment',
  templateUrl: './legacy-apartment.component.html',
  styleUrls: ['./legacy-apartment.component.css']
})
export class LegacyApartmentComponent implements OnInit {
  @Input() apartmentData: any;
  constructor() { }

  ngOnInit() {
  }

}
