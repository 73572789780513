import { Component, OnInit } from '@angular/core';
import { Email } from '../email';
import { windowWhen } from 'rxjs/operators';
import { ApartmentInfoService } from '../apartment-info.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  model = new Email('', '', '', '', '');
  sending = 0;
  constructor(private apartmentInfoService: ApartmentInfoService) { }

  ngOnInit() {
  }
  get diagnostic() { return JSON.stringify(this.model); }
  validateAndSend(e) {
    let valid = true;
    let messages = '';
    if (e.email === '') { messages += '<p>Please specify email</p>'; valid = false; }
    if (!valid) {window.alert(JSON.stringify(messages)); } else {
      this.sending = 1;
      this.apartmentInfoService.sendEmail(this.model).subscribe(res => {
         console.log('Result:' + JSON.stringify(res));
         if (res.result === 'ERROR') {
           this.sending = 3; // errore
        } else {
           this.sending = 2; // OK
        }
        });
    }
  }
}
