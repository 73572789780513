import {Component, Input, OnInit} from '@angular/core';
import { ApartmentInfoService } from '../apartment-info.service';
import { Route, Params } from '@angular/router';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.css']
})
export class RightSidebarComponent implements OnInit {
    areas: string;
    neighborhoods: string;
    aptTypes: any;
    checkin = '0';
    checkinobj: {year: '0', month: '0', day: '0'};
    checkoutobj: {year: '0', month: '0', day: '0'};
    checkout = '0';
    selectedarea = '0';
    selectedguests = '0';
    selectedbedrooms = '0';
    selectedbathrooms = '0';
    selectedneighborhoods = '0';
    selectedfacilities = '0';
    recentApt: any;
    @Input() apartmentData: any;

  constructor(
      private apartmentInfoService: ApartmentInfoService,
      private route: ActivatedRoute) {

  }

    ngOnInit() {
        this.apartmentInfoService.getAreas()
            .subscribe(apt => {
                this.areas = JSON.parse(JSON.stringify(apt)); console.log('&&&' + JSON.stringify(apt));
                setTimeout(() => {
                    $('.selectpicker').selectpicker('refresh');
                }, 150);
            });
        this.apartmentInfoService.getAptTypes().subscribe(apt => { this.aptTypes = apt; console.log('&&&' + JSON.stringify(apt)); });
        this.apartmentInfoService.getNeighborhoods().subscribe(apt => {
            this.neighborhoods = apt; console.log('&&&' + JSON.stringify(apt));
            setTimeout(() => {
                $('.selectpicker').selectpicker('refresh');
            }, 150);

        });

    }

    public trackByFn(index, item) {
        if (!item) { return null; }
        return item.Id;
    }

    updateCheckin() {
        console.log(this.checkinobj);
        const fyear = this.checkinobj.year.toString();
        const fmonth = this.checkinobj.month.toString();
        const fday = this.checkinobj.day.toString();
        let fdate = parseInt(fyear, 10) < 1000 ? '0000' : fyear;
        fdate += parseInt(fmonth, 10) < 10 ? '-0' + fmonth : '-' + fmonth;
        fdate += parseInt(fday, 10) < 10 ? '-0' + fday : '-' + fday;
        console.log(fdate);
        this.checkin = fdate;
    }

    updateCheckout() {
        console.log(this.checkoutobj);
        const fyear = this.checkoutobj.year.toString();
        const fmonth = this.checkoutobj.month.toString();
        const fday = this.checkoutobj.day.toString();
        let fdate = parseInt(fyear, 10) < 1000 ? '0000' : fyear;
        fdate += parseInt(fmonth, 10) < 10 ? '-0' + fmonth : '-' + fmonth;
        fdate += parseInt(fday, 10) < 10 ? '-0' + fday : '-' + fday;
        console.log(fdate);
        this.checkout = fdate;
    }


}
