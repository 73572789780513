import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Academies } from './academies';
import { Options } from './options';
import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AcademiesService {
  academies: any;

  apiBaseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) {  }

  getAcademies(): Observable<any> {
    // const academies = 'http://127.0.0.1:3000/api/getAcademies';  // URL to web api;
    const academies = this.apiBaseUrl + 'api/getAcademies';  // URL to web api;
    // const academies = 'https://www.florenceapartments.com/API/api/getAcademies';  // URL to web api;
    console.log(academies);
    return this.http.get<any[]>(academies);
  }

}


