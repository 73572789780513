import { Component, OnInit, Input } from '@angular/core';
import {FeaturedService} from '../featured.service';

@Component({
  selector: 'app-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.css']
})
export class FeaturedComponent implements OnInit {
  apartmentData: string;
  constructor(private featuredService: FeaturedService) { }


  ngOnInit() {
    this.getFeatured();
  }

  getFeatured(): void {
    this.featuredService.getFeaturedApartments()
    .subscribe(apt => this.apartmentData = apt);

  }

}
