export class Email {

 constructor(
    public name: string,
    public email: string,
    public subject: string,
    public phone: string,
    public message: string
  ) {  }

}
