import { Component, OnInit, Input } from '@angular/core';
import { FeaturedService } from '../featured.service';
import { Apartment } from '../apartment';
import { Route, Params } from '@angular/router';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { async } from 'q';
import {map} from 'rxjs/internal/operators';

@Component({
  selector: 'app-legacy-properties',
  templateUrl: './legacy-properties.component.html',
  styleUrls: ['./legacy-properties.component.css']
})
export class LegacyPropertiesComponent implements OnInit {
  apartmentData: any;
  aptData: Observable<any>;
  URLPrefix: string;
  Images: string[];
  Facilities: string[];
  Lat: number;
  Lon: number;


  // Calendars properties
  nothingToshowText: any = 'Nothing to show'; // "By default" => There are no events scheduled that day.
  availabilityColors: any = {
    1: {
        primary: '#23ad13',
        secondary: '#23ad13',
    },
    2: {
        primary: '#23ad13',
        secondary: '#23ad13'
    },
    3: {
        primary: '#ffb6c1',
        secondary: '#ffb6c1'

    }
  };
  availabilityLabels: any = {
    1: {
      title: 'Available'
    },
    2: {
      title: 'Available'
    },
    3: {
      title: 'Not available'
    }
  };
  actions: any[] = [
    /*{
      label: '<i class="fa fa-fw fa-times"></i>',
      name: 'delete'
    },
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      name: 'edit'
    }*/
  ];
  events$: Observable<any[]>;
  viewDate: Date = new Date('2019-01-01');
  themecolor: any = '#0a5ab3';

  constructor(
    private apartmentService: FeaturedService,
    private route: ActivatedRoute,
    private router: Router,
    private service: FeaturedService) { }

  ngOnInit() {
    this.URLPrefix = 'http://www.housinginflorence.com';
    this.route.params.subscribe((params: Params) => {
      console.log(params);
      this.getApartmentByNid(params.nid);
      // console.log(this.apartmentData);
      this.getApartmentAvailability(params.nid);

    });
  }

  nextYear(event): void {
    let year: number;
    year = this.viewDate.getFullYear() + 1;
    this.viewDate = new Date(this.viewDate.setFullYear(year));
  }

  prevYear(event): void {
    let year: number;
    year = this.viewDate.getFullYear() - 1;
    this.viewDate = new Date(this.viewDate.setFullYear(year));
  }

  eventClicked(event) {
    console.log(event);
  }
  actionClicked(event) {
    console.log('action', event.action);
    console.log('event', event.event);
  }

  getApartmentAvailability(nid: string): void {
    this.events$ = this.apartmentService.getAvailabilityApartment(nid)
      .pipe( map(availabilities => {
          return availabilities.map(availability => {
              let event: any;
              event = {
                start: new Date(availability.Date),
                end: new Date(availability.Date),
                title: this.availabilityLabels[availability.State].title,
                color: this.availabilityColors[availability.State],
                actions: this.actions
              };
              return event;
          });
      }));
  }

  getApartmentByNid(nid: string): void {
    console.log('NID=' + nid);
    this.apartmentService.getFeaturedApartment(nid)
      .subscribe(apt => {
        this.apartmentData = apt[0];
        console.log(this.apartmentData.Image);
          console.log(this.apartmentData.Latitude);
          console.log(this.apartmentData.Longitude);
        this.Images = this.apartmentData.Image.split(';');
        this.Facilities = this.apartmentData.Facilities.split(',');
        this.Lon = parseFloat(this.apartmentData.Longitude);
        this.Lat = parseFloat(this.apartmentData.Latitude);
        //this.Images.unshift(this.apartmentData.Copertina);
      });
  }

  isFirst(i: number, type: string) {
    if (i === 0) {
      return type;
    }
    return;
  }

}



