import { Component, OnInit } from '@angular/core';
import { Email } from '../email';
import { ApartmentInfoService } from '../apartment-info.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.css']
})
export class ProposalComponent implements OnInit {
  model = new Email('', '', '', '', '');
  sending = 0;
  firstName = '';
  lastName = '';
  email = '';
  bedrooms = 0;
  beds = 0;
  guests = 0;
  bathrooms = 0;
  floor = 0;
  description = '';
  facilities = '';
  outdoorFacilities = '';
  canSubmit = 0;
  recaptchaKey = environment.recaptchaKey;

  constructor(private apartmentInfoService: ApartmentInfoService) { }


  ngOnInit() {
  }
  get diagnostic() { return JSON.stringify(this.model); }

  handleCorrectCaptcha(e) {
    this.canSubmit = 1;
  }

  buildMail() {
   this.model.email = this.email;
   this.model.subject = 'Apartment submission from ' + this.firstName + ' ' + this.lastName;
   this.model.name = this.firstName + ' ' + this.lastName;
   this.model.message = 'Name: ' + this.firstName + ' ' + this.lastName + '<br/>';
   this.model.message += 'Email: ' + this.email + '<br/>';
   this.model.message += 'Bedrooms: ' + this.bedrooms + '<br/>';
   this.model.message += 'Beds: ' + this.beds + '<br/>';
   this.model.message += 'Guests: ' + this.guests + '<br/>';
   this.model.message += 'Bathrooms: ' + this.bathrooms + '<br/>';
   this.model.message += 'Floor: ' + this.floor + '<br/>';
   this.model.message += 'Description: <br/><pre>' + this.description + '</pre><br/>';
   this.model.message += 'Facilities: <br/><pre>' + this.facilities + '</pre><br/>';
   this.model.message += 'Outdoor Facilities: <br/><pre>' + this.outdoorFacilities + '</pre><br/>';
   // console.log(this.model);
   this.validateAndSend( this.model );
  }
  validateAndSend(e) {
    let valid = true;
    let messages = '';
    if (e.email === '') { messages += '<p>Please specify email</p>'; valid = false; }
    if (!valid) {window.alert(JSON.stringify(messages)); } else {
      this.sending = 1;
      this.apartmentInfoService.sendEmail(this.model).subscribe(res => {
         console.log('Result:' + JSON.stringify(res));
         if (res.result === 'ERROR') {
           this.sending = 3; // errore
        } else {
           this.sending = 2; // OK
        }
        });
    }
  }
}
