import { Injectable } from '@angular/core';
import { Apartment } from './apartment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApartmentService {
  constructor(  private http: HttpClient) { }
  getApartment(num: number): Observable<any> {
    const apartmentUrl = 'http://151.236.61.42:3001/api/getApartmentById/' + num;  // URL to web api;
    console.log(apartmentUrl);
    return this.http.get<any[]>(apartmentUrl);
  }
  getApartmentByOid(oid: string): Observable<any> {
    const apartmentUrl = 'http://151.236.61.42:3001/api/getApartmentByOid/' + oid;  // URL to web api;
    console.log(apartmentUrl);
    return this.http.get<any[]>(apartmentUrl);
  }
  getFeaturedApartments(): Observable<any> {
    const apartmentUrl = 'https://www.housinginflorence.com/data-exp-apt-json?nid=all';  // URL to web api;
    console.log(apartmentUrl);
    return this.http.get<any[]>(apartmentUrl);
  }
}
