import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { Options } from './options';
import { environment } from './../environments/environment';
import { Email } from './email';

@Injectable({
  providedIn: 'root'
})

export class ApartmentInfoService {
  apartments: any;
  options: any;
  // apiBaseUrl = 'http://31.200.243.41:3000/';
  apiBaseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) {
    console.log('*API:' + this.apiBaseUrl);
    this.options = { area: {}, aptType: {}, neighborhood: {} };
  }
  getFeaturedApartments(): Observable<any> {
    const apartmentUrl = this.apiBaseUrl + 'api/getFeaturedApartments';  // URL to web api;
    console.log(apartmentUrl);
    return this.http.get<any[]>(apartmentUrl);
  }
  getFeaturedApartment(nid: string): Observable<any> {
    const apartmentUrl = this.apiBaseUrl + 'api/getFeaturedApartment/' + nid;  // URL to web api;
    console.log(apartmentUrl);
    return this.http.get<any[]>(apartmentUrl);
  }
  loadApartments(): Observable<any> {
    const apartmentUrl = this.apiBaseUrl + 'api/getApartmentsData';  // URL to web api;
    console.log(apartmentUrl);
    this.apartments = this.http.get<any>(apartmentUrl);
    return this.apartments;
  }
  getAreas(): Observable<any> {
    const optionsUrl = this.apiBaseUrl + 'api/relay';  // URL to web api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(optionsUrl,
      JSON.stringify({ url: 'https://www.housinginflorence.com/service/list/area' }), httpOptions);
  }

  getAptTypes(): Observable<any> {
    const optionsUrl = this.apiBaseUrl + 'api/relay';  // URL to web api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(optionsUrl,
      JSON.stringify({ url: 'https://www.housinginflorence.com/service/list/type' }), httpOptions);
  }

  getRecentApartments(): Observable<any> {

    const optionsUrl = this.apiBaseUrl + 'api/relay';  // URL to web api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(optionsUrl,
      JSON.stringify({ url: 'https://www.housinginflorence.com/service/apt/recent' }), httpOptions);

  }

  getNeighborhoods(): Observable<any> {
    const optionsUrl = this.apiBaseUrl + 'api/relay';  // URL to web api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(optionsUrl,
      JSON.stringify({ url: 'https://www.housinginflorence.com/service/list/neighborhood' }), httpOptions);
  }

  getSearchedApartments(area: string, guests: string, checkin: string, checkout: string, bedrooms: string, bathrooms: string, facilities: string): Observable<any> {
    const optionsUrl = this.apiBaseUrl + 'api/relay';  // URL to web api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let myurl = 'https://www.housinginflorence.com/service/apt/search?du=mmy';
    myurl += area === '0' ? '' : '&area=' + area;
    myurl += guests === '0' ? '' : '&guests=' + guests;
    myurl += bedrooms === '0' ? '' : '&bedrooms=' + bedrooms;
    myurl += bathrooms === '0' ? '' : '&bathrooms=' + bathrooms;
    myurl += facilities === '0' ? '' : '&facilities=' + facilities;
    myurl += checkin !== '0' || checkout !== '0' ? '&calendars=' : '';
    myurl += checkin !== '0' ? checkin : '';
    myurl += checkin !== '0' || checkout !== '0' ? '--' : '';
    myurl += checkout !== '0' ? checkout : '';
    console.log(checkin + '()()()()' + checkout);


    return this.http.post<any>(optionsUrl,
      JSON.stringify({ url: myurl }), httpOptions);
  }



  getCustomUrl(url: string): Observable<any> {
    const optionsUrl = this.apiBaseUrl + 'api/relay';  // URL to web api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const myurl = 'https://www.housinginflorence.com/' + url;
    return this.http.post<any>(optionsUrl,
      JSON.stringify({ url: myurl }), httpOptions);
  }




  loadOptions(): Promise<any> {
    const optionsUrl = this.apiBaseUrl + 'api/relay';  // URL to web api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    console.log(optionsUrl);
    const p1 = this.http.post<any>(optionsUrl,
      JSON.stringify({ url: 'https://www.housinginflorence.com/service/list/area' }), httpOptions)
      .toPromise();
    const p2 = this.http.post<any>(optionsUrl,
      JSON.stringify({ url: 'https://www.housinginflorence.com/service/list/type' }), httpOptions)
      .toPromise();
    const p3 = this.http.post<any>(optionsUrl,
      JSON.stringify({ url: 'https://www.housinginflorence.com/service/list/neighborhood' }), httpOptions)
      .toPromise();
    /*const p = Promise.all([p1, p2, p3]).then( r =>  {
      this.options.area = r[0];
      this.options.aptType = r[1];
      this.options.neighborhood = r[2];
      console.log( '***' + JSON.stringify(this.options ));
    });
    */
    const p = Promise.all([p1, p2, p3]);
    return p;

  }


  getApartmentsData(): any {
    return this.apartments;
  }

  getOptions(): Observable<any> {
    console.log(this.options);
    return this.options;
  }

  sendEmail(e: Email): Observable<any> {
    const optionsUrl = this.apiBaseUrl + 'api/emailSend';  // URL to web api;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        responseType: 'text/html'
      })
    };
    const mailToUs = this.http.post<any>(optionsUrl, {
      from: environment.fromAddr,
      to: environment.bccAddr,
      subject: 'Info request from ' + e.email,
      html: e.message
    }, httpOptions);
    const mailToCustomer = this.http.post<any>(optionsUrl, {
      from: environment.fromAddr,
      to: e.email,
      subject: 'Thank you for your submission',
      html: environment.thankYou
    }, httpOptions);
    const p = forkJoin([mailToUs, mailToCustomer]);
    return p;
    /*
        return this.http.post<any>(optionsUrl, {
          from: 'FlorenceApartments Web',
          to: 'info@florenceapartments.com',
          subject: 'Info request from ' + e.email,
          html: e.message}, httpOptions);
    */
  }



}


