
import { Component, OnInit, Input } from '@angular/core';
import { Email } from '../email';
import { windowWhen } from 'rxjs/operators';
import { ApartmentInfoService } from '../apartment-info.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.css']
})
export class EmailFormComponent implements OnInit {
  @Input() subject: string;
  model = new Email('', '', '', '', '');
  sending = 0;
  canSubmit = 0;
  recaptchaKey = environment.recaptchaKey;

  // constructor(private apartmentInfoService: ApartmentInfoService) { }
  constructor(private apartmentInfoService: ApartmentInfoService, route: ActivatedRoute) {
    route.params.subscribe(val => {
    console.log('form reinit ****');
      // put the code from `ngOnInit` here
    });
  }

  ngOnInit() {
    console.log('form init ****');
    // if (this.subject) {this.model.subject = this.subject; }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges() {
    if (this.subject) {this.model.subject = this.subject; }
  }
  get diagnostic() { return JSON.stringify(this.model); }

  handleCorrectCaptcha(e) {
    this.canSubmit = 1;
  }

  validateAndSend(e) {
    let valid = true;
    let messages = '';
    if (e.email === '') { messages += '<p>Please specify email</p>'; valid = false; }
    if (!valid) {window.alert(JSON.stringify(messages)); } else {
      this.sending = 1;
      this.apartmentInfoService.sendEmail(this.model).subscribe(res => {
         console.log('Result:' + JSON.stringify(res));
         if (res.result === 'ERROR') {
           this.sending = 3; // errore
        } else {
           this.sending = 2; // OK
        }
        });
    }
  }
}



