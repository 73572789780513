import { Component, OnInit, Input } from '@angular/core';
import { ApartmentInfoService } from './apartment-info.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit {
  title = 'hif';
  apartmentData: string;
  apartmentOptions: any;
  constructor(private apartmentInfoService: ApartmentInfoService) {


  }


  ngOnInit() {
    this.apartmentInfoService.loadApartments();
    //this.apartmentInfoService.loadOptions()
    //.then( o => {console.log('+++' + JSON.stringify(o)); this.apartmentOptions = o; } );
    //.then( () => this.apartmentInfoService.getOptions()
    //.subscribe(opt => this.apartmentOptions = opt));
    //this.initOptions();
    this.apartmentInfoService.getApartmentsData()
      .subscribe(apt => this.apartmentData = apt);

  }

  getFeatured(): void {
    this.apartmentInfoService.getApartmentsData()
      .subscribe(apt => this.apartmentData = apt);
  }

  initOptions() {
    this.apartmentInfoService.loadOptions()
      .then(r => {
        this.apartmentOptions = {area: r[0], aptType: r[1], neighborhood: r[2]};
      }
      );
    console.log('+++++' + JSON.stringify(this.apartmentInfoService.options));
  }

}
