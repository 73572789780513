import { Component, OnInit, Input } from '@angular/core';
import { ApartmentService } from '../apartment.service';
import { Apartment } from '../apartment';
import { Route, Params } from '@angular/router';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { async } from 'q';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css']
})
export class PropertiesComponent implements OnInit {
  // @Input() oid: string;
  apartmentData: string;
  aptData: Observable<any>;
  URLPrefix: string;
  Images: string[];
  constructor(
    private apartmentService: ApartmentService,
    private route: ActivatedRoute,
    private router: Router,
    private service: ApartmentService) { }

  ngOnInit() {
    this.URLPrefix = 'http://www.housinginflorence.com';
    this.route.params.subscribe((params: Params) => {
      console.log(params);
      this.getApartmentByNid(params.nid);
      console.log(this.apartmentData);
    });
  }

  getApartmentByNid(nid: string): void {
    console.log('NID=' + nid);
    this.apartmentService.getApartmentByOid(nid)
      .subscribe(apt => {
        this.apartmentData = apt;
        console.log(apt.Image);
        this.Images = apt.Image.split('\n');
        this.Images.unshift(apt.Copertina);
      });
  }
}

