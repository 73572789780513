import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApartmentComponent } from './apartment/apartment.component';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomepageComponent } from './homepage/homepage.component';
import { RentalprocessComponent } from './rentalprocess/rentalprocess.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ContactComponent } from './contact/contact.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { PropertiesComponent } from './properties/properties.component';
import { FeaturedComponent } from './featured/featured.component';
import { LegacyApartmentComponent } from './legacy-apartment/legacy-apartment.component';
import { LegacyPropertiesComponent } from './legacy-properties/legacy-properties.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { SearchedApartmentComponent } from './searched-apartment/searched-apartment.component';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { PropertyGridComponent } from './property-grid/property-grid.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecentPropComponent } from './recent-prop/recent-prop.component';
import { AcademiesComponent } from './academies/academies.component';
import { LifeComponent } from './life/life.component';
import { EmailFormComponent } from './email-form/email-form.component';
import { AngularCalendarYearViewModule } from 'angular-calendar-year-view';
import { AgmCoreModule } from '@agm/core';
import { ProposalComponent } from './proposal/proposal.component';
import { ReCaptchaModule } from 'angular2-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    ApartmentComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    RentalprocessComponent,
    FaqComponent,
    PrivacyComponent,
    ContactComponent,
    TermsconditionsComponent,
    PropertiesComponent,
    FeaturedComponent,
    LegacyApartmentComponent,
    LegacyPropertiesComponent,
    PropertyListComponent,
    SearchedApartmentComponent,
    RightSidebarComponent,
    PropertyGridComponent,
    RecentPropComponent,
    AcademiesComponent,
    LifeComponent,
    EmailFormComponent,
    ProposalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AngularCalendarYearViewModule,
    NgbModule,
    ReCaptchaModule,
    AgmCoreModule.forRoot({
       apiKey: 'AIzaSyC-QZs2aotoC3kb8Tvrx2FPI0sA3ftPppM'
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
