import { Component, OnInit, Input  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApartmentInfoService } from '../apartment-info.service';
// import { url } from 'inspector';

@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.css']
})
export class PropertyListComponent implements OnInit {
  apartmentData: any;
  paramlist: any;
  paramsurl: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apartmentInfoService: ApartmentInfoService ) {
  }

  ngOnInit() {
    this.route.url.subscribe(ustr => {
      this.paramsurl = ';' + ustr.toString().replace(/^.*?;/, '');
    });
    this.route.params.subscribe(params => {
      const areaid = params.area;
      const guests = params.guests;
      const checkin = params.checkin;
      const checkout = params.checkout;
      const bedrooms = params.bedrooms;
      const bathrooms = params.bathrooms;
      const facilities = params.bathrooms;
      this.paramlist = params;
      if (params.customurl) { this.getUrl(params.customurl);
      } else { this.getSearched(areaid, guests, checkin, checkout, bedrooms, bathrooms, facilities ); }
      console.log('^^^' + areaid);
   });
  }


  getUrl(url): void {
    this.apartmentInfoService.getCustomUrl(url)
    .subscribe(apt => {this.apartmentData = apt; console.log ('$$$' + apt); } );
  }

  getSearched(areaid,guests,checkin,checkout, bedrooms, bathrooms, facilities): void {
    this.apartmentInfoService.getSearchedApartments(areaid, guests, checkin, checkout, bedrooms, bathrooms, facilities)
    .subscribe(apt => {this.apartmentData = apt; console.log ('$$$' + apt); } );
  }

}
