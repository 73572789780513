import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-searched-apartment',
  templateUrl: './searched-apartment.component.html',
  styleUrls: ['./searched-apartment.component.css']
})
export class SearchedApartmentComponent implements OnInit {

  constructor() { }
  @Input() aptlayout: string;
  @Input() apt: any;
  ngOnInit() {
  }

}
