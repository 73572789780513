import { Component, OnInit } from '@angular/core';
import {FeaturedService} from "../featured.service";
import {AcademiesService} from "../academies.service";

@Component({
  selector: 'app-academies',
  templateUrl: './academies.component.html',
  styleUrls: ['./academies.component.css']
})
export class AcademiesComponent implements OnInit {
    academiesData: string;
    URLPrefix: string;
    constructor(private academiesService: AcademiesService) { }

    ngOnInit() {
        this.URLPrefix = 'http://www.housinginflorence.com';
        this.getAcademies();
    }

    getAcademies(): void {
        this.academiesService.getAcademies()
            .subscribe(academies => this.academiesData = academies);

    }
}
