import { Component, OnInit, Input } from '@angular/core';
import { ApartmentService } from '../apartment.service';
import { Apartment } from '../apartment';
import { ActivatedRoute, Route, Params } from '@angular/router';

@Component({
  selector: 'app-apartment',
  templateUrl: './apartment.component.html',
  styleUrls: ['./apartment.component.css']
})
export class ApartmentComponent implements OnInit {
  @Input() numero: number;
  apartmentData: string;
  constructor(private apartmentService: ApartmentService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getApartment();
  }

  getApartment(): void {
    this.apartmentService.getApartment(this.numero)
    .subscribe(apt => this.apartmentData = apt);

  }

}


