import {Component, Input, OnInit} from '@angular/core';
import { ApartmentInfoService } from '../apartment-info.service';

@Component({
  selector: 'app-recent-prop',
  templateUrl: './recent-prop.component.html',
  styleUrls: ['./recent-prop.component.css']
})
export class RecentPropComponent implements OnInit {


  constructor(private apartmentInfoService: ApartmentInfoService) { }
  @Input() recentApt: any;
  ngOnInit() {
      this.apartmentInfoService.getRecentApartments().subscribe(apt => { this.recentApt = apt; console.log('&&&' + JSON.stringify(apt)); });
  }




}
