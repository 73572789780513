import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import {RentalprocessComponent} from './rentalprocess/rentalprocess.component';
import {FaqComponent} from './faq/faq.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {ContactComponent} from './contact/contact.component';
import {TermsconditionsComponent} from './termsconditions/termsconditions.component';
import { PropertiesComponent } from './properties/properties.component';
import { LegacyPropertiesComponent } from './legacy-properties/legacy-properties.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { PropertyGridComponent } from './property-grid/property-grid.component';
import {AcademiesComponent} from './academies/academies.component';
import {LifeComponent} from './life/life.component';
import {ProposalComponent} from './proposal/proposal.component';

const routes: Routes = [
  { path: 'homepage', component: HomepageComponent },
  { path: 'rental-process', component: RentalprocessComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'terms-conditions', component: TermsconditionsComponent },
  { path: 'search', component: PropertyListComponent },
  { path: 'grid', component: PropertyGridComponent },
  { path: 'properties-details/:oid', component: PropertiesComponent },
  { path: 'legacy-properties-details/:nid', component: LegacyPropertiesComponent },
  { path: 'academies', component: AcademiesComponent },
  { path: 'life', component: LifeComponent },
  { path: 'proposal', component: ProposalComponent },
  { path: '', redirectTo: '/homepage', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
